import { Dialog, Transition } from "@headlessui/react"
import { Link } from "gatsby"
// eslint-disable-next-line no-unused-vars
import React, { Fragment } from "react"
import LanguageSelector from "./language-selector"
import { useTranslate } from "../../hooks/use-translation"

// import { useCustomer } from "../../hooks/use-customer"
// import { useRegion } from "../../hooks/use-region"
// import { useRegionDisabled } from "../../hooks/use-region-disabled"
// import { classNames } from "../../utils/class-names"

const MobileMenu = ({ open, setOpen }) => {
  const t = useTranslate();
  const pages = [
    {
      name: t('mobile_menu.universe'),
      path: "https://in-form.io/univers",
    },
    {
      name: t('mobile_menu.read_about'),
      path: "https://in-form.io/om-os",
    },
    {
      name: t('mobile_menu.for_clubs'),
      path: "https://in-form.io/klubber",
    },
    {
      name: t('mobile_menu.buy_your_card'),
      path: "/byg-dit-kort",
    },
  ]

  // const {
  //   regions,
  //   region,
  //   country,
  //   actions: { updateRegion },
  // } = useRegion()

  // const disabled = useRegionDisabled()

  // const { customer } = useCustomer()

  // const handleRegionChange = e => {
  //   const { region, country } = JSON.parse(e.target.value)
  //   updateRegion(region, country)
  // }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 flex lg:hidden"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-white bg-opacity-25" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex w-full max-w-md flex-col overflow-y-auto bg-[#2C2C2C] pb-12 shadow-xl">
            <div className="flex px-4 pb-2 pt-5">
              <button
                type="button"
                className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-white"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">{t('mobile_menu.close_menu')}</span>
                &times;
              </button>
            </div>

            <div className="space-y-6 border-t border-gray-200 px-4 py-6">
              <div className="flow-root">
                <Link
                  to={"/"}
                  className="-m-2 block p-2 font-medium text-white"
                  onClick={() => setOpen(false)}
                >
                  {t('mobile_menu.home')}
                </Link>
              </div>
            </div>

            <div className="space-y-6 border-t border-gray-200 px-4 py-6">
              {pages.map(page => (
                <div key={page.name} className="flow-root">
                  <Link
                    to={page.path}
                    className="-m-2 block p-2 font-medium text-white"
                    onClick={() => setOpen(false)}
                  >
                    {page.name}
                  </Link>
                </div>
              ))}
            </div>



            {/* Log in options. */}
            {/* <div className="space-y-6 border-t border-gray-200 px-4 py-6">
              {customer ? (
                <Fragment>
                  <div className="flow-root">
                    <Link
                      to="/account"
                      className="-m-2 block p-2 font-medium text-white"
                      onClick={() => setOpen(false)}
                    >
                      Account
                    </Link>
                  </div>
                  <div className="flow-root">
                    <Link
                      to="/account/order-history"
                      className="-m-2 block p-2 font-medium text-white"
                      onClick={() => setOpen(false)}
                    >
                      Order History
                    </Link>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="flow-root">
                    <Link
                      to="/sign-in"
                      className="-m-2 block p-2 font-medium text-white"
                      onClick={() => setOpen(false)}
                    >
                      Sign in
                    </Link>
                  </div>
                  <div className="flow-root">
                    <Link
                      to="/sign-up"
                      className="-m-2 block p-2 font-medium text-white"
                      onClick={() => setOpen(false)}
                    >
                      Create account
                    </Link>
                  </div>
                </Fragment>
              )}
            </div> */}

            <div
              className={"border-t border-gray-200 px-4 py-6"}
              // className={classNames(
              //   disabled ? "hidden" : "border-t border-gray-200 px-4 py-6"
              // )}
            >
              <LanguageSelector inlineButton={false}/>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

export default MobileMenu
