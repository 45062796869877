import { StaticImage } from "gatsby-plugin-image"
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react"

import CartPopover from "./cart-popover"
import MobileMenu from "./mobile-menu"
import Banner from "./banner"
import {useTranslate} from "../../hooks/use-translation"
import { Language } from "@mui/icons-material"
import LanguageSelector from "./language-selector"

const Header = () => {
  const [open, setOpen] = useState(false)
  const [clubIdInLocalStorage, setClubIdInLocalStorage] = useState(false)

  const t = useTranslate();

  useEffect(() => {
    setClubIdInLocalStorage(localStorage.getItem("id") || false)
  }, [clubIdInLocalStorage])

  return (
    <div className="sticky top-0 z-50">
      <header className="bg-[rgba(255, 255, 255, 0.05)] relative h-[80px] backdrop-blur-sm">
        <Banner />
        <MobileMenu open={open} setOpen={setOpen} />
        <nav
          aria-label="Top"
          className="flex h-full items-center justify-between px-4 lg:px-8 w-screen"
        >
          <div className="flex items-center">
            <div className="flex h-16 items-center">
              {/* Hamburger Menu */}
              <button
                type="button"
                className="bg-transparent p-2 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">{t('header_index.open_menu')}</span>
                <svg
                  className="h-6 w-6 fill-white"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
              {/* Logo */}
              <div
                className="absolute left-1/2 top-[70px] h-[29px] w-[223px] -translate-x-1/2
 -translate-y-1/2 transform lg:left-[200px]"
              >
                {/* <Link to="/"> */}
                <StaticImage
                  className="clickEffect"
                  placeholder="none"
                  src="../../icons/logo.svg"
                  alt=""
                />
                {/* </Link> */}
              </div>
            </div>
          </div>
          <div className={"flex items-center justify-end gap-4"}>
            <div className="hidden lg:flex">
              {/* <RegionPopover regions={mockData.regions} /> */}
              {/* <AccountPopover customer={mockData.customer} /> */}
              {/* Byg dit kort - button disabled */}
              {/* Attempt to insert byg dit kort button using an image */}
              {/* <div>
                <StaticImage
                  id="byg-dit-kort-test"
                  loading="eager"
                  className="!static w-[200px]"
                  alt="byg dit kort"
                  src="/images/button-byg-dit-kort.webp"
                />
              </div> */}
              <button
                className="box-border flex h-10 cursor-pointer flex-row items-center justify-center gap-[10px] rounded-sm bg-[transparent] px-8 py-[7px]"
                onClick={() => {
                  window.location.href = "https://in-form.io/"
                }}
              >
                <div className="100 relative text-center font-mulish text-sm text-white">
                {t('header_index.home')}
                </div>
              </button>
              <button
                className="box-border flex h-10 cursor-pointer flex-row items-center justify-center gap-[10px] rounded-sm bg-[transparent] px-8 py-[7px]"
                onClick={() => {
                  window.location.href = "https://in-form.io/univers" // FIXME: insert correct link
                }}
              >
                <div className="100 relative text-center font-mulish text-sm text-white">
                {t('header_index.unvierse')}
                </div>
              </button>
              <button
                className="box-border flex h-10 cursor-pointer flex-row items-center justify-center gap-[10px] rounded-sm bg-[transparent] px-8 py-[7px]"
                onClick={() => {
                  window.location.href = "https://in-form.io/om-os"
                }}
              >
                <div className="100 relative text-center font-mulish text-sm text-white">
                {t('header_index.read_about')}
                </div>
              </button>
              <button
                className="box-border flex h-10 cursor-pointer flex-row items-center justify-center gap-[10px] rounded-sm bg-[transparent] px-8 py-[7px]"
                onClick={() => {
                  window.location.href = "https://in-form.io/klubber"
                }}
              >
                <div className="100 relative text-center font-mulish text-sm text-white">
                {t('header_index.for_clubs')}
                </div>
              </button>
              <button
                className="me-8 clickEffect box-border flex h-[40px] cursor-pointer flex-row items-center justify-center gap-[10px] rounded-md bg-[transparent] px-[25px] py-[7px] [background:linear-gradient(85.24deg,_#47b5a1,_#307789)] [border:none]"
                onClick={() => {
                  window.location.href = "https://byg.in-form.io/byg-dit-kort"
                }}
              >
                <div className="relative h-[20px] text-center font-regular text-[16px] text-white">
                {t('header_index.buy_your_card')}
                </div>

                
                {/* <svg
                  width={9}
                  height={14}
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13.5 8 7 1 .5"
                    stroke="#FFF"
                    strokeLinejoin="round"
                  />
                </svg> */}
              </button>

                {/* {regions.map((region, index) => {
                  return (
                    <Fragment key={index}>
                      {region.countries.map(country => {
                        return (
                          <option
                            key={country.display_name}
                            value={JSON.stringify({
                              region: region,
                              country: country.display_name,
                            })}
                          >
                            {country.display_name} /{" "}
                            {region.currency_code.toUpperCase()}
                          </option> 
                        )
                      })}
                    </Fragment>
                  )
                })} */}
            </div>

        <div className="relative inline-block text-left pr-4 hidden lg:flex">
          <LanguageSelector inlineButton={true} />
        </div>
              <CartPopover />
      </div>
        </nav>
      </header>
    </div>
  )
}

export default Header
