import React from "react"
import { formatPrice } from "../../utils/format-price"
import { useTranslate } from "../../hooks/use-translation"
import { useRegion } from "../../hooks/use-region";

const CartPopoverItem = ({ item, currencyCode }) => {
  const t = useTranslate();
  const {region} = useRegion();
  if(item.metadata !== undefined && item.metadata.itemType === "playercard") {

  return (
    <div className="flex">
      <div className="mr-4 w-1/4 overflow-hidden ">
        <img className="h-auto w-auto" src={item.metadata.playercardImage} alt={item.title} />
      </div>
      <div className="flex flex-col justify-between py-2">
        <div>
          <p className="text-sm font-medium">{item.title}</p>
          <p className="mt-1 text-xs">
            <span className="text-ui-dark">{t('cart_popover_item.card_type')}</span> {item.description}
          </p>
          <p className="my-4 max-w-[200px] text-xs uppercase">
            <span className="my-2 text-xs normal-case text-ui-dark">
            {t('cart_popover_item.your_info')}:
            </span>{" "}
            {item.metadata.barnetsFornavn}{" "}
            {item.metadata.barnetsEfternavn},{" "}
            {item.metadata.aargang},{" "}
            {item.metadata.position}, NR.{" "}
            {item.metadata.troejenummer},{" "}
            {item.metadata.favoritben},{" "}
            {item.metadata.yndlingsklub}
          </p>
        </div>
        <div className="flex items-center text-xs font-light">
          <p>
            <span className="text-ui-dark">{t('cart_popover_item.quantity')}</span>{" "}
            <span>{item.quantity} x 25 stk.</span>
          </p>
          <div className="mx-3 h-4 w-px bg-ui-dark" />
          <p>
            <span className="text-ui-dark">{t('cart_popover_item.price')}</span>{" "}
            <span>
              {formatPrice(item.unit_price + item.unit_price * (region?.tax_rate/100 || 0.25),currencyCode, item.quantity)}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
} else {
  return (
    <div className="flex">
      <div className="mr-4 w-1/3 overflow-hidden ">
        <img className="h-auto w-auto" src={item.thumbnail} alt={item.title} />
      </div>
      <div className="flex flex-col justify-between py-2">
        <div>
          <p className="text-sm font-medium">{item.title}</p>
          

          <p>
            <span className="my-2 text-ui-dark">{item.variant?.product?.subtitle}</span> 
          </p>
        </div>
        <div className="flex items-center text-xs font-light">
          <p>
            <span className="text-ui-dark">{t('cart_popover_item.quantity')}</span>{" "}
            <span>{item.quantity} {item.metadata.itemType == "playercard" ? "x 25 stk." : ""}</span>
          </p>
          <div className="mx-3 h-4 w-px bg-ui-dark" />
          <p>
            <span className="text-ui-dark">{t('cart_popover_item.price')}</span>{" "}
            <span>
              {formatPrice(item.unit_price + item.unit_price * (region?.tax_rate/100 || 0.25), currencyCode, item.quantity)}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}
}

export default CartPopoverItem
