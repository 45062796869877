// eslint-disable-next-line no-unused-vars
import React, { createContext, useEffect, useState } from "react"

import { useMedusa } from "../hooks/use-medusa"
import { useRegion } from "../hooks/use-region"
import { navigate } from "gatsby"

const defaultCartContext = {
  cart: {
    items: [],
  },
  loading: false,
  actions: {
    updateCart: () => {},
    resetCart: () => {},
    addCardItem: async () => {},
    removeItem: async () => {},
    updateQuantity: async () => {},
    addDiscount: async () => {},
    removeDiscount: async () => {},
    createPaymentSession: async () => {},
    setPaymentSession: async () => {},
    completeCart: async () => {},
    getCartShippingOptions: async () => {},
    addShippingMethod: async () => {},
  },
}

const CartContext = createContext(defaultCartContext)
export default CartContext

const CART_ID = "cart_id"
const isBrowser = typeof window !== "undefined"

export const CartProvider = props => {
  const [cart, setCart] = useState(defaultCartContext.cart)
  const [loading, setLoading] = useState(defaultCartContext.loading)
  const client = useMedusa()
  const { region } = useRegion();

  const setCartItem = cart => {
    if (isBrowser) {
      localStorage.setItem(CART_ID, cart.id)
    }

    setCart(cart)
  }

  useEffect(() => {
    const initializeCart = async () => {
      const existingCartId = isBrowser ? localStorage.getItem(CART_ID) : null

      if (existingCartId && existingCartId !== null) {
        try {
          const existingCart = await client.carts
            .retrieve(existingCartId)
            .then(({ cart }) => cart)
          if (!existingCart.completed_at) {
            setCartItem(existingCart)
            return
          }
        } catch (e) {
          localStorage.setItem(CART_ID, null)
        }
      }

      const newCart = await client.carts.create({}).then(({ cart }) => cart)
      setCartItem(newCart)
      setLoading(false)
    }

    initializeCart()
  }, [client.carts])

  useEffect(() => {
    const updateCartRegion = async () => {
      setLoading(true)

      const cartId = cart.id

      if (cart.region) {
        const isEqual = cart.region.id === region.id
        if (isEqual) {
          setLoading(false)
          return
        }
      }

      const cartRes = await client.carts
        .update(cartId, { region_id: region.id })
        .then(({ cart }) => cart)

      if (cartRes) {
        setCart(cartRes)
      }

      setLoading(false)
    }

    if (cart.id) {
      updateCartRegion()
    }
  }, [cart.id, cart.region, region?.id, client.carts])

  const addCardItem = async item => {
    setLoading(true)

    let cartId = cart.id
    // console.log("cartId", cartId)


    const metadata = {
      
      playercardProfilePicture: localStorage.getItem(
        "playercardProfilePicture"
      ),
      playerImageSize: localStorage.getItem("playerImageSize"),
      playerImageX: localStorage.getItem("playerImageX"),
      playerImageY: localStorage.getItem("playerImageY"),
      barnetsFornavn: localStorage.getItem("barnetsFornavn"),
      barnetsEfternavn: localStorage.getItem("barnetsEfternavn"),
      aargang: localStorage.getItem("aargang"),
      position: localStorage.getItem("position"),
      troejenummer: localStorage.getItem("troejenummer"),
      favoritben: localStorage.getItem("favoritben"),
      yndlingsklub: localStorage.getItem("yndlingsklub"),
      playercardImage: localStorage.getItem("playercardImage"),
      printFrontImage: localStorage.getItem("printFrontImage"),
      itemType: "playercard"
    }
    for (let member in metadata) {
      localStorage.removeItem(member)
    }
    

    item.metadata = metadata

    // console.log(item.metadata)
    if (!cartId) {
      const newCart = await client.carts.create({}).then(({ cart }) => cart)
      cartId = newCart.id
      setCartItem(newCart)
    }
    // console.log(item)

    return client.carts.lineItems.create(cartId, item).then(({ cart }) => {
      setCart(cart)
      setLoading(false)
    })
  }


  // For items with no metadata
  const addOtherItem = async item => {
    setLoading(true)

    let cartId = cart.id

    if (!cartId) {
      const newCart = await client.carts.create({}).then(({ cart }) => cart)
      cartId = newCart.id
      setCartItem(newCart)
    }

    return client.carts.lineItems.create(cartId, item).then(({ cart }) => {

      setCart(cart)
      setLoading(false)
    })
  }

  const removeItem = async id => {
    setLoading(true)

    const cartId = cart.id

    return client.carts.lineItems.delete(cartId, id).then(({ cart }) => {
      setCart(cart)
      setLoading(false)
    })
  }

  const updateQuantity = async item => {
    setLoading(true)

    const cartId = cart.id

    return client.carts.lineItems
      .update(cartId, item.id, { quantity: item.quantity })
      .then(({ cart }) => {
        // if (item.quantity === 0)
        //   localStorage.removeItem("playercardAddedToCart")
        setCart(cart)
        setLoading(false)
      })
  }

  const addDiscount = async discount => {
    setLoading(true)

    const cartId = cart.id

    return client.carts
      .update(cartId, { discounts: [{ code: discount }] })
      .then(({ cart }) => {
        setCart(cart)
        setLoading(false)
      })
  }

  const removeDiscount = async () => {
    setLoading(true)

    const cartId = cart.id

    return client.carts.update(cartId, { discounts: [] }).then(({ cart }) => {
      setCart(cart)
      setLoading(false)
    })
  }

  const getCartShippingOptions = async (providedCartId = null) => {
    setLoading(true)

    const cartId = providedCartId || cart.id

    return client.shippingOptions
      .listCartOptions(cartId)
      .then(({ shipping_options }) => {
        setLoading(false)
        return shipping_options
      })
  }

  const addShippingMethod = async payload => {
    setLoading(true)

    const cartId = cart.id

    return client.carts.addShippingMethod(cartId, payload).then(({ cart }) => {
      setCart(cart)
      setLoading(false)
    })
  }

  const updateCart = async payload => {
    setLoading(true)

    const cartId = cart.id

    const metadata = {
      // playercardProfilePicture: localStorage.getItem(
      //   "playercardProfilePicture"
      // ),
      // playerImageSize: localStorage.getItem("playerImageSize"),
      // playerImageX: localStorage.getItem("playerImageX"),
      // playerImageY: localStorage.getItem("playerImageY"),
      // barnetsFornavn: localStorage.getItem("barnetsFornavn"),
      // barnetsEfternavn: localStorage.getItem("barnetsEfternavn"),
      // aargang: localStorage.getItem("aargang"),
      // position: localStorage.getItem("position"),
      // troejenummer: localStorage.getItem("troejenummer"),
      // favoritben: localStorage.getItem("favoritben"),
      // yndlingsklub: localStorage.getItem("yndlingsklub"),
      // TODO: fix only danish
      emailDate: new Date().toLocaleDateString("da-DK", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      moms: `${Number((cart.total * 0.2) / 100).toLocaleString("da-DK", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })} DKK`,
    }

    for (let member in metadata) {
      if (metadata[member] == null)
        throw new Error(`Undefined value in metadata: ${member}`)
    }

    // Code for generating engine url to store in Worldline
    const queryParams = {
      id: localStorage.getItem("id"), // TODO: get this dynamically from handle
      engine: true,
      barnetsFornavn: localStorage.getItem("barnetsFornavn"),
      barnetsEfternavn: localStorage.getItem("barnetsEfternavn"),
      aargang: localStorage.getItem("aargang"),
      position: localStorage.getItem("position"),
      troejenummer: localStorage.getItem("troejenummer"),
      favoritben: localStorage.getItem("favoritben"),
      yndlingsklub: localStorage.getItem("yndlingsklub"),
      playerImageX: localStorage.getItem("playerImageX"),
      playerImageY: localStorage.getItem("playerImageY"),
      playerImageSize: localStorage.getItem("playerImageSize"),
      playercardProfilePicture: localStorage.getItem(
        "playercardProfilePicture"
      ),
    }
    const baseUrl = new URL("https://byg.in-form.io/byg-dit-kort")
    baseUrl.search = new URLSearchParams(queryParams)
    const engineUrl = baseUrl.href.replaceAll("%22", "")

    const metadata_billing_address = {
      engine_url: engineUrl,
    }

    const modifiedPayload = {
      ...{ shipping_address: { metadata } },
      ...{ billing_address: { metadata: metadata_billing_address } },
      ...payload,
    }

    return client.carts.update(cartId, modifiedPayload).then(({ cart }) => {
      setCart(cart)
      setLoading(false)
    })
  }

  const createPaymentSession = async (providedCartId = null) => {
    // console.log("CREATING PAYMENT SESSION");
    setLoading(true)
  
    const cartId = providedCartId ?? cart.id
    // console.log(cartId);
    return await client.carts.createPaymentSessions(cartId)
      .then(({ cart }) => {
        // console.log("FINISHED CREATING PAYMENT SESSION");
        // console.log(cart);
        if (cart.total === 0) {
          localStorage.setItem("freePayment", true)
        } else {
          localStorage.setItem("freePayment", false)
        }
        
        setCart(cart)
        setLoading(false)
      })
      .catch(error => {
        console.error("Error occurred:", error);
        if (error.response && error.response.data) {
          // console.log("Response body:", error.response.data);
        }
        setLoading(false);
        window.alert("Der skete en fejl. Prøv venligst igen.")
        navigate("/byg-dit-kort")
      });
  }

  const setPaymentSession = async (providerId, providedCartId = null) => {
    setLoading(true)

    const cartId = providedCartId ?? cart.id
    // console.log("INVOKING SET PAYMENT SESH");
    // console.log('below is rhe acrt');
    return await client.carts
      .setPaymentSession(cartId, { provider_id: providerId })
      .then(({ cart }) => {
        // console.log("FINISHED SETTING PAYMENT SESSION");
        // console.log(cart);
        setCart(cart)
        setLoading(false)
        return cart
      }).catch(error => {
        console.error("Error setting payment session:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("Request data:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
      });
  }

  const completeCart = async (providedCartId = null) => {
    setLoading(true)

    const cartId = providedCartId ?? cart.id
    // console.log(client.carts);
    // console.log(order);
    return client.carts.complete(cartId).then(({ data: order }) => {
      // console.log("COMPLETE CART SUCCESSFUL AND RESETTING CART");
      setCart(defaultCartContext.cart)
      setLoading(false)
      return order
    })
  }

  return (
    <CartContext.Provider
      {...props}
      value={{
        ...defaultCartContext,
        loading,
        cart,
        actions: {
          addCardItem: addCardItem,
          addOtherItem: addOtherItem,
          removeItem,
          updateQuantity,
          addDiscount,
          removeDiscount,
          createPaymentSession,
          setPaymentSession,
          completeCart,
          getCartShippingOptions,
          addShippingMethod,
          updateCart,
        },
      }}
    />
  )
}
