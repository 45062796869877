import React, { useEffect, useState } from "react"
import { classNames } from "../../utils/class-names"
import { useTranslate } from "../../hooks/use-translation"

const Banner = () => {
  const [isHidden, setIsHidden] = useState(true)
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  const t = useTranslate();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  function calculateTimeLeft() {
    // let now = new Date()
    // let year = now.getFullYear()
    // let weekNumber = Math.floor(
    //   (now - new Date(year, 0, 1)) / (7 * 24 * 60 * 60 * 1000)
    // )
    // let nextSunday

    // if (weekNumber % 1 === 0) {
    //   nextSunday = new Date(
    //     now.getFullYear(),
    //     now.getMonth(),
    //     now.getDate() + ((7 - now.getDay()) % 7) + 7
    //   )
    // } else {
    //   nextSunday = new Date(
    //     now.getFullYear(),
    //     now.getMonth(),
    //     now.getDate() + ((7 - now.getDay()) % 7)
    //   )
    // }

    // let difference = nextSunday - now
    let now = new Date();
let nextMonday;

// Calculate the days remaining until the next Monday
let daysUntilNextMonday = (1 - now.getDay() + 7) % 7 || 7;

// Create the date for the next Monday
nextMonday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysUntilNextMonday);

let difference = nextMonday - now;

    let timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    }

    return timeLeft
  }

  useEffect(() => {
    if (localStorage) {
      setIsHidden(localStorage.getItem("hideBannerMsg") === "true")
    }
  }, [])

  const hideBanner = () => {
    localStorage.setItem("hideBannerMsg", "true")
    setIsHidden(true)
  }

  return (
    <div
      className={classNames(
        isHidden ? "hidden" : "",
        "flex h-[30px] place-content-center items-center bg-[#4299B1]"
      )}
    >
      <p className="text-center text-[14px] text-white">
        <b>
          {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m{" "}
          {timeLeft.seconds}s{" "}
        </b>{" "}
        {t('banner.next_production')}
      </p>
      {/* Close button */}
      {/* <button onClick={() => hideBanner()}>&times;</button> */}
    </div>
  )
}

export default Banner
