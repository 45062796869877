import React, { createContext, useReducer, useEffect } from "react";

// Define the default state of the context
const defaultRegionContext = {
  region: undefined,
  country: undefined,
  regions: [],
  updateRegion: () => {},
  loading: true,  // Start with loading set to true
};

// Create the context
const RegionContext = createContext(defaultRegionContext);
export default RegionContext;

// Define actions for the reducer
const ACTIONS = {
  UPDATE_REGION: "UPDATE_REGION",
  SET_LOADING: "SET_LOADING",
};

// Reducer function to handle state updates
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_REGION:
      return {
        ...state,
        region: action.payload.region,
        country: action.payload.country,
        regions: action.payload.regions,
        loading: false, // Set loading to false when regions are updated
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

// RegionProvider component that fetches region data and provides it to children
export const RegionProvider = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(reducer, {...defaultRegionContext, loading: true});

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        // const medusaBackendUrl = "https://sea-turtle-app-7c86t.ondigitalocean.app/"
        const medusaBackendUrl =
          !process.env.NODE_ENV || process.env.NODE_ENV === "development"
            ? "http://localhost:9000/"
            : process.env.MEDUSA_BACKEND ||
              "https://sea-turtle-app-7c86t.ondigitalocean.app/"
        const response = await fetch(medusaBackendUrl + 'store/regions');
        const jsonResponse = await response.json();
        const regions = jsonResponse.regions;
        dispatch({
          type: ACTIONS.UPDATE_REGION,
          payload: { regions: regions, region: regions[0], country: regions[0].countries[0] },
        });
      } catch (error) {
        console.error("Failed to fetch regions:", error);
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };

    fetchRegions();
  }, []);

  // Function to update the region
  const updateRegion = (region, country) => {
    localStorage.setItem("medusa_region", JSON.stringify(region));
    localStorage.setItem("medusa_country", JSON.stringify(country));
    dispatch({
      type: ACTIONS.UPDATE_REGION,
      payload: { region: region, country: country },
    });
    // console.log(region)
  };

  // Render children only if loading is false
  return (
    <RegionContext.Provider
      {...props}
      value={{ ...state, updateRegion }}
    >
      {!state.loading ? children : <div></div>}
    </RegionContext.Provider>
  );
};
