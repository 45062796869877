import React, { useState, useEffect } from 'react';
import UKImage from "../../images/UK.png";
import DKImage from "../../images/DK.png";

const LanguageSelector = ({ inlineButton }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'da');

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  const changeToLanguage = (languageCode) => {
    setSelectedLanguage(languageCode);
    localStorage.setItem('language', languageCode);
    window.onbeforeunload = null;
    window.location.reload();
  };

  if (inlineButton) {
    if (selectedLanguage === 'da') {
      return (
        <img
          width="32px"
          src={DKImage}
          className="pointer-events-auto underline underline-offset-2"
          onClick={() => changeToLanguage('en')}
        />
      );
    } else if (selectedLanguage === 'en') {
      return (
        <img
        width="32px"
          src={UKImage}
          className="pointer-events-auto underline underline-offset-2"
          onClick={() => changeToLanguage('da')}
        />
      );
    }
  } else {
    return (
      <select
        className="rounded-md border-none text-black shadow-lg bg-white px-4 py-2 pe-10"
        onChange={(e) => changeToLanguage(e.target.value)}
        value={selectedLanguage}
      >
        <option key="Dansk" value="da">Dansk</option>
        <option key="English" value="en">English</option>
      </select>
    );
  }
};

export default LanguageSelector;
