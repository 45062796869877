import React, { useState, useEffect, useContext, createContext } from 'react';

// Create a Context
const TranslationContext = createContext();

// Provider in your app root
export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'da');
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    import(`../locales/${language}/translation.json`)
      .then((module) => {
        setTranslations(module.default);
      })
      .catch((error) => {
        console.error("Failed to load translation file: ", error);
      });

    const handleStorageChange = (event) => {
      if (event.key === 'language') {
        setLanguage(localStorage.getItem('language'));
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);

  }, [language]);

  const t = (key) => {
    return key.split('.').reduce((acc, part) => acc && acc[part] ? acc[part] : null, translations);
  };

  return (
    <TranslationContext.Provider value={t}>
      {children}
    </TranslationContext.Provider>
  );
};

// Hook to use the context
export const useTranslate = () => useContext(TranslationContext);
