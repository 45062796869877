import "./src/styles/theme.css"

import Medusa from "@medusajs/medusa-js"
// eslint-disable-next-line no-unused-vars
import React from "react"

import Layout from "./src/components/layout"
import { MedusaProvider } from "./src/context/medusa-context"
import { TranslationProvider } from "./src/hooks/use-translation"

const BASE_URL =
  // eslint-disable-next-line no-undef
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  ? "http://localhost:9000"
  : process.env.MEDUSA_BACKEND ||
    "https://sea-turtle-app-7c86t.ondigitalocean.app/";

const medusaClient = new Medusa({ baseUrl: BASE_URL })

export const wrapPageElement = ({ element, props }) => {
  return (
    <MedusaProvider client={medusaClient}>
      <TranslationProvider>
      <Layout {...props}>{element}</Layout>
      </TranslationProvider>
    </MedusaProvider>
  )
}