exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-betalingsfejl-js": () => import("./../../../src/pages/betalingsfejl.js" /* webpackChunkName: "component---src-pages-betalingsfejl-js" */),
  "component---src-pages-byg-dit-kort-js": () => import("./../../../src/pages/byg-dit-kort.js" /* webpackChunkName: "component---src-pages-byg-dit-kort-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-handelsbetingelser-js": () => import("./../../../src/pages/handelsbetingelser.js" /* webpackChunkName: "component---src-pages-handelsbetingelser-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kurv-js": () => import("./../../../src/pages/kurv.js" /* webpackChunkName: "component---src-pages-kurv-js" */),
  "component---src-pages-privatlivspolitik-js": () => import("./../../../src/pages/privatlivspolitik.js" /* webpackChunkName: "component---src-pages-privatlivspolitik-js" */),
  "component---src-pages-tak-for-din-ordre-js": () => import("./../../../src/pages/tak-for-din-ordre.js" /* webpackChunkName: "component---src-pages-tak-for-din-ordre-js" */),
  "component---src-pages-vores-mission-js": () => import("./../../../src/pages/vores-mission.js" /* webpackChunkName: "component---src-pages-vores-mission-js" */)
}

