// eslint-disable-next-line no-unused-vars
import React from "react"

const ShoppingBagIcon = () => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.417 20.167c-.505 0-.936-.18-1.295-.538a1.766 1.766 0 0 1-.539-1.296c0-.504.18-.936.54-1.295.358-.359.79-.538 1.293-.538.505 0 .936.18 1.295.538.36.36.539.791.539 1.295 0 .505-.18.936-.54 1.296-.358.358-.79.538-1.293.538Zm9.166 0c-.504 0-.935-.18-1.294-.538a1.766 1.766 0 0 1-.54-1.296c0-.504.18-.936.54-1.295.359-.359.79-.538 1.294-.538s.936.18 1.295.538c.36.36.538.791.538 1.295 0 .505-.179.936-.538 1.296-.359.358-.79.538-1.295.538ZM5.637 5.5l2.2 4.583h6.417l2.52-4.583H5.638Zm.78 10.083c-.688 0-1.207-.302-1.559-.905-.351-.604-.366-1.203-.046-1.799l1.238-2.246-3.3-6.966h-.94a.849.849 0 0 1-.641-.264.907.907 0 0 1-.252-.653c0-.26.087-.478.264-.654a.886.886 0 0 1 .652-.263h1.49c.168 0 .328.046.481.138a.855.855 0 0 1 .344.39l.619 1.306h13.52c.413 0 .696.152.848.458.153.306.145.626-.023.963l-3.254 5.866c-.168.306-.39.542-.664.71a1.766 1.766 0 0 1-.94.253h-6.83L6.417 13.75h10.107a.85.85 0 0 1 .641.263.91.91 0 0 1 .252.654c0 .26-.088.477-.264.652a.885.885 0 0 1-.652.264H6.416Z"
        fill="#fff"
      />
    </svg>
  )
}

export default ShoppingBagIcon
