import { Menu } from "@headlessui/react"
import { Link } from "gatsby"
// eslint-disable-next-line no-unused-vars
import React from "react"

import { useCart } from "../../hooks/use-cart"
import ShoppingBagIcon from "../../icons/shopping-bag"
import PopoverTransition from "../popover-transition"
import CartPopoverItem from "./cart-popover-item"
import { useTranslate } from "../../hooks/use-translation"

const CartPopover = () => {
  const t = useTranslate();
  const { cart } = useCart();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="clickEffect hover:opacity-1/2 inline-flex w-full items-center justify-center bg-transparent py-2 text-sm font-medium text-white">
          <ShoppingBagIcon />
          <span>{cart.items.reduce((sum, i) => sum + i.quantity, 0)}</span>
        </Menu.Button>
      </div>

      <PopoverTransition>
        <Menu.Items className="absolute right-0 mt-2 w-96 origin-top-right  bg-ui-box_dark px-6 py-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {cart.items < 1 ? (
              <div className="flex justify-center">
                <p>{t('cart_popover.empty_cart')}</p>
              </div>
            ) : (
              <>
                {cart.items.map((item, i) => {
                  return (
                    <div className="py-2 first:pt-0" key={i}>
                      <Menu.Item>
                        {() => (
                          <CartPopoverItem
                            item={item}
                            currencyCode={cart.region.currency_code}
                          />
                        )}
                      </Menu.Item>
                    </div>
                  )
                })}
                <div className="mt-4 flex flex-col">
                  <Menu.Item>
                    <Link to="/checkout">
                      <button className="btn-ui mb-2 w-full px-4 py-2 text-sm font-medium">
                      {t('cart_popover.check_out')}
                      </button>
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/kurv">
                      <button className="text-ui-white w-full py-2 text-sm">
                      {t('cart_popover.go_to_kart')}
                      </button>
                    </Link>
                  </Menu.Item>
                </div>
              </>
            )}
          </div>
        </Menu.Items>
      </PopoverTransition>
    </Menu>
  )
}

export default CartPopover
